.slick-slide > div {
  margin: 0 5px;
}

.portfolio--section {
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-bottom-right-radius: 25%;
  border-top-left-radius: 25%;
}

.portfolio--container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}

.portfolio--section--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42.6px;
  width: 100%;
}

/* .portfolio--section--container :hover {
  transform: scale(0.9);
  transition: all 0.7s;
} */

.portfolio--section--img {
  border-radius: 8px;
  width: 100%;
}

.portfolio--section--img > img {
  width: 100%;
}

.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding-top: 2%;
}

.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}

.portfolio--section--title {
  color: var(--heading-color);
}

.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}

.portfolio--section--card:hover path {
  stroke: #006b6a;
}

.live-link {
  text-decoration: none;
  color: #000;
}

.live-link:hover {
  background-color: #5e3bee;
  color: #fff;
}

.Github-link {
  text-decoration: none;
  color: #fff;
}

.right-arrow {
  width: 20px;
  height: 20px;
}

.github-icon {
  width: 32px;
}

.github-live {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
}

.date-type {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* My Projects Ends */

/* Responsive Screens */

@media only screen and (max-width: 1200px) {
  .portfolio--section--container,
  .portfolio--container-box {
    display: flex;
    flex-direction: column;
  }

  .portfolio--container {
    align-items: center;
  }

  .portfolio--container-box {
    gap: 30px;
  }

  .portfolio--section {
    padding: 80px 40px;
  }
}
