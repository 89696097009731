.footer--container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  background: #afe1af;
  align-items: center;
  align-self: stretch;
}

.footer--link--container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: stretch;
}

.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.footer--items ul > li > a {
  text-decoration: none;
}

.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}

.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}

.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens Starts */

@media only screen and (max-width: 1200px) {
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }

  .footer--container {
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }

  .divider {
    margin: 20px;
  }
}

/* Responsive Screens Ends */
